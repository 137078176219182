const  router = [
	{
		path: "/apply",
		component: () => import('../../../pages/apply.vue'),
		meta: {title: '申请列表'},
		name: 'apply',
		pname:'apply',
	},
];

export default router