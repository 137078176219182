import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import  './assets/css/font.css'
import './assets/css/common.css'
Vue.use(Antd)
Vue.config.productionTip = false
import VCharts from 'v-charts';
Vue.use(VCharts);

import XLSX from 'xlsx';
Vue.use(XLSX);

import Print from 'vue-print-nb'
Vue.use(Print);

import  router from './common/router/router';

import  * as http  from './common/request/request.js';

import store from './store'
Vue.prototype.$http = http;

import {adminInfo} from './common/mixin/adminInfo.js';
Vue.mixin(adminInfo);

new Vue({
 router,
 store,
  render: h => h(App),
}).$mount('#app')




