const  router = [
	{
		path: "/material",
		component: () => import('../../../pages/material.vue'),
		meta: {title: '物资管理'},
		name: 'material',
		pname:'material',
	},
];

export default router