const  router = [
	{
		path: "/account",
		component: () => import('../../../pages/account.vue'),
		meta: {title: '账号管理'},
		name: 'account',
		pname:'account',
	},
];

export default router