<template>
    <div>
        <a-result
            status="404"
            title="页面不存在"
            subTitle="对不起, 您访问的页面弄丢了."
        >
            <template v-slot:extra>
                 <router-link to="/"><a-button type="primary">返回</a-button></router-link>
            </template>
        </a-result>
    </div>
</template>
<script>
export default {
    
    created(){
        
    },
}
</script>