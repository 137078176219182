<template>
	<div>
		<a-modal title="修改密码" :width="480" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
					    <a-form-model-item label="原密码">
					      <a-input type="password"    placeholder="请输入原密码" v-model="form.old_password" />
					    </a-form-model-item>
						
						<a-form-model-item label="新密码">
						  <a-input type="password"    placeholder="请输入新密码" v-model="form.password" />
						</a-form-model-item>
						
						<a-form-model-item label="确认密码">
						  <a-input type="password"    placeholder="请输入确认密码" v-model="form.confirm_password" />
						</a-form-model-item>
						
					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			admin_id:{
				type:Number,
				default:0,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				add_password:'',
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
				form: {
					old_password: '',
					password: '',
					confirm_password: '',
				}
			}
		},
		
		methods: {
			
			
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/editPassword', {
					admin_id:this.admin_id,
					old_password: this.form.old_password,
					password: this.form.password,
					confirm_password: this.form.confirm_password,
				}).then(res => {
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading = false;
						this.$emit("ok");
					});
					
				}).catch(res => {
					this.confirmLoading = false;
					console.log(res);
				})


			},
			
			
		}
	}
</script>

<style>
	.input-box .ant-input-affix-wrapper .ant-input {
		padding-left: 60px;
	}
</style>
