const  router = [
	{
		path: "/takegoods",
		component: () => import('../../../pages/takegoods.vue'),
		meta: {title: '领货单位'},
		name: 'takegoods',
		pname:'takegoods',
	},
];

export default router