<template>
  <div>
    <div class="bg-w pd20" style="min-height: 950px">
      <a-spin :spinning="loading">
        <div class="mb20 flex alcenter" v-if="datas.length > 0">
          <div class="mr20">
            请输入打印几个二维码
            <a-input-number
              :formatter="(value) => `${value} 个`"
              v-model="pageSize"
              :min="12"
              :precision="0"
              placeholder="请输入打印几个"
              @change="changeSize"
            />
          </div>
          <a-button type="primary" v-print="printObj">打印页面</a-button>
        </div>
        <div
          class="flex wrap"
          v-if="datas.length > 0"
          id="printMe"
          ref="printContent"
        >
          <div class="qrcode-item" v-for="(item, index) in datas">
            <!-- <img :src="baseUrl+item.qrcode" /> -->
            <img :src="'https://www.bgpfly.com/' + item.qrcode" />
            <div class="flex alcenter center">
              <div class="text-center text-over4 cl-notice nowrap">名称：</div>
              <div class="text-center text-over4 cl-main hiddle">
                {{ item.name }}
              </div>
            </div>
            <div class="flex alcenter center">
              <div class="text-center text-over4 cl-notice nowrap">型号：</div>
              <div class="text-center text-over4 cl-main hiddle">
                {{ item.specifications }}
              </div>
            </div>
            <div class="flex alcenter center">
              <div class="text-center text-over4 cl-notice nowrap">编号：</div>
              <div class="text-center text-over4 cl-main hiddle">
                {{ item.spare_number }}
              </div>
            </div>
          </div>
        </div>

        <div v-else class="flex center mt40">
          <a-empty />
        </div>

        <div class="flex center mt20">
          <a-pagination
            @change="pageChange"
            :total="total"
            v-model="page"
            :pageSize="pageSize"
            show-less-items
          />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { listMixin } from '../common/mixin/list.js'
export default {
  mixins: [listMixin],

  data() {
    return {
      loading: false,
      pageSize: 12,
      printObj: {
        id: 'printMe',
        popTitle: '打印',
        // extraCss: 'https://www.google.com,https://www.google.com',
        // extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      datas: [],
    }
  },
  methods: {
    getLists() {
      if (this.loading == true) return
      this.loading = true
      this.$http
        .api('admin/getQrcodeList', {
          limit: this.pageSize,
          page: this.page,
        })
        .then((res) => {
          this.total = res.total
          this.datas = res.list
          this.loading = false
        })
        .catch((res) => {
          console.log(res)
          this.loading = false
        })
    },

    changeSize() {
      this.getLists()
    },
  },
}
</script>

<style>
.qrcode-item {
  /* background: #007AFF; */
  width: 10%;
  /* height:300px; */
  margin-right: 1%;
  margin-bottom: 2px;
}

.qrcode-item img {
  width: 100%;
  /* height: 230px; */
}
.alcenter .text-center {
  font-size: 12px;
}
.hiddle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nowrap{
    white-space:nowrap;
}
</style>
