const  router = [
	{
		path: "/index",
		component: () => import('../../../pages/index.vue'),
		meta: {title: '控制台'},
		name: 'index',
		pname:'index',
	},
];

export default router