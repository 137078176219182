const  router = [
	{
		path: "/usermanlist",
		component: () => import('../../../pages/usermanlist.vue'),
		meta: {title: '领用人列表'},
		name: 'usermanlist',
		pname:'usermanlist',
	},
];

export default router