<template>
    <div>
          <a-result
              status="404"
              title="没有权限"
              subTitle="对不起, 您访问的暂时没有权限."
          >
              <template v-slot:extra>
                   <router-link to="/"><a-button type="primary">返回</a-button></router-link>
              </template>
          </a-result>
    </div>
</template>
<script>
export default {
   data(){
	   return {
		   
	   }
   }
}
</script>