import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)
// 定义模块
// 提供初始 state 对象
export default new Vuex.Store({
  state: {
    adminInfo: localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : {},
	baseUrl:process.env.VUE_APP_BASE_API,
	// adminInfo: localStorage.getItem('adminInfo') ? localStorage.getItem('adminInfo') : {},
  },
  mutations: {
    adminInfo(state) {
      state.adminInfo = {}
    },
    setAdminInfo(state, adminInfo) {
      state.adminInfo = adminInfo
    },
  },
  // getters: {
  //   adminInfo: state => state.adminInfo,
  // },
 
// 现在，你可以通过 store.state 来获取状态对象，以及通过 store.commit 方法触发状态变更：
  // actions: {
  //   adminInfo({ commit }) {
  //     commit('adminInfo')
  //   },
  //   setAdminInfo({ commit }, adminInfo) {
  //     commit('setAdminInfo', adminInfo)
  //   },
  // }
})