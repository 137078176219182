<template>
	<div>
		<div class=" pd20" style="min-height: 800px;">
			<div v-if="show">
				<a-spin :spinning="loading">
					<div class="pd20 bg-w" style="border-radius: 8px;">
						<div class="flex alcenter mb10">
							<div class="ft14 ftw600 cl-info">查看的库房：</div>
							<div class="ft16 ftw600 cl-black">{{room.name}}</div>
						</div>
						<a-alert message="所有数据都是截取前五位" banner />
						
					</div>
					<div class="mt10">
						<a-row :gutter="[16,16]">
							<a-col :span="12">
								<div class="count-box">
									<div class="flex ">
										<div>
											<i class="iconfont iconoperation_cate04 cl-green ft24" />
										</div>
										<div class="ml15 cl-black ft18 ftw600">出库较多</div>
									</div>
						
									<div class="mt20" v-if="this.pieChartData.rows.length>0">
										<ve-pie :data="pieChartData" height="400px" :setting="chartPieSettings"></ve-pie>
									</div>
									
									<div class="mt20 flex center alcenter" v-else style="height: 400px;">
										<a-empty/>
									</div>
								</div>
							</a-col>
							<a-col :span="12">
								<div class="count-box">
									<div class="flex ">
										<div>
											<i class="iconfont icondata05 cl-red ft24" />
										</div>
										<div class="ml15 cl-black ft18 ftw600">库存预警</div>
									</div>
						
									<div class="mt20" v-if="this.histogramChartData.rows.length>0">
										<ve-histogram :data="histogramChartData" height="400px" :setting="chartHistogramSettings"></ve-histogram>
									</div>
									<div class="mt20 flex center alcenter" v-else style="height: 400px;">
										<a-empty/>
									</div>
								</div>
							</a-col>
						</a-row>
						<a-row :gutter="[16,16]">
							<a-col :span="12">
								<div class="count-box">
									<div class="flex ">
										<div>
											<i class="iconfont icontabbar01 cl-gray ft24" />
										</div>
										<div class="ml15 cl-black ft18 ftw600">僵尸库存</div>
									</div>
							
									<div class="mt20" v-if="this.lineChartData.rows.length>0">
										<ve-line :data="lineChartData" height="400px" :setting="chartLineSettings"></ve-line>
									</div>
									<div class="mt20 flex center alcenter" v-else style="height: 400px;">
										<a-empty/>
									</div>
								</div>
							</a-col>
							<a-col :span="12">
								<div class="count-box">
									<div class="flex ">
										<div>
											<i class="iconfont iconclient_tab03 cl-orange ft24" />
										</div>
										<div class="ml15 cl-black ft18 ftw600">占地库存</div>
									</div>
							
									<div class="mt20" v-if="this.pieOccupiedChartData.rows.length>0">
										<ve-pie :data="pieOccupiedChartData" height="400px" :setting="chartPieOccupiedSettings"></ve-pie>
									</div>
									<div class="mt20 flex center alcenter" v-else style="height: 400px;">
										<a-empty/>
									</div>
								</div>
							</a-col>
						</a-row>
					</div>
				</a-spin>
			</div>
			<div v-else class="flex center mt40">
				<a-empty :description="error_msg"/>
			</div>
		</div>
		<div v-if="chooseRoomVisible">
			<choose-room :visible="chooseRoomVisible" v-model="room_id" @ok="okChooseRoom"></choose-room>
		</div>
	</div>
</template>

<script>
	import chooseRoom from './components/index/modal/chooseRoom.vue';
	export default {
		components:{
			chooseRoom,
		},
		data() {
			this.chartPieSettings = {
				metrics: ['出库数量'],
				dimension: ['物资']
			};
			this.chartHistogramSettings = {
				metrics: ['库存数量'],
				dimension: ['物资']
			};
			this.chartLineSettings = {
				metrics: ['库存数量'],
				dimension: ['物资']
			};
			this.chartPieOccupiedSettings = {
				metrics: ['库存数量'],
				dimension: ['物资']
			};
			return {
				loading:false,
				show:false,
				chooseRoomVisible:false,
				room_id:0,
				error_msg:'您还未设置基本设置，请先设置',
				room:{
					name:'',
				},
				pieChartData: {
					columns: ['物资', '出库数量'],
					rows: [{
							'物资': '苹果',
							'出库数量': '5',
						},
						{
							'物资': '香蕉',
							'出库数量': '6',
						}
					]
				},
				
				histogramChartData: {
					columns: ['物资', '库存数量'],
					rows: [{
							'物资': '苹果',
							'库存数量': '5',
						},
						{
							'物资': '香蕉',
							'库存数量': '6',
						}
					]
				},
				
				lineChartData: {
					columns: ['物资', '库存数量'],
					rows: [{
							'物资': '苹果',
							'库存数量': '5',
						},
						{
							'物资': '香蕉',
							'库存数量': '6',
						}
					]
				},
				
				pieOccupiedChartData: {
					columns: ['物资', '库存数量'],
					rows: [{
							'物资': '苹果',
							'库存数量': '5',
						},
						{
							'物资': '香蕉',
							'库存数量': '6',
						}
					]
				},
			}
		},
		created() {
			if(this.adminInfo.role_id==1){
				this.chooseRoomVisible=true;
			}else{
				this.loaddata();
			}
		},
		computed: {

		},
		methods: {
			loaddata(){
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/showStatistic', {
					room_id:this.room_id,
				}).then(res => {
					this.room=res.room;
					this.pieChartData.rows=res.delivery_chart_data;
					this.histogramChartData.rows=res.warnning_chart_data;
					this.lineChartData.rows=res.corpse_chart_data;
					this.pieOccupiedChartData.rows=res.occupied_chart_data;
					this.show=true;
					this.loading = false;
				}).catch(res => {
					this.error_msg=res.msg;
					this.loading = false;
				})
			},
			
			okChooseRoom(){
				this.chooseRoomVisible=false;
				this.loaddata();
			},
		}
	}
</script>

<style>
	.count-box {
		background: #ffffff;
		line-height: 1;
		padding: 20px;
		min-height: 60px;
		border-radius: 8px;
	}
</style>
