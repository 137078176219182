const  router = [
	{
		path: "/supply",
		component: () => import('../../../pages/supply.vue'),
		meta: {title: '供货单位'},
		name: 'supply',
		pname:'supply',
	},
];

export default router