const  router = [
	{
		path: "/setting",
		component: () => import('../../../pages/setting.vue'),
		meta: {title: '基本设置'},
		name: 'setting',
		pname:'setting',
	},
];

export default router