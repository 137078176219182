<template>
  <div>
    <div class="bg-w pd20" style="min-height: 950px">
      <div class="form-seach-box">
        <a-form layout="inline">
          <a-form-item label="领用人名称">
            <a-input
              v-model="search.name"
              placeholder="请输入单位名称"
            ></a-input>
          </a-form-item>

          <a-form-item>
            <a-button @click="searchAct" type="primary">查询</a-button>
            <a-button @click="getLists" class="ml10 mr10">取消</a-button>
            <a-button @click="addClick" type="primary">添加</a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- 添加弹窗 -->
      <a-modal
        title="添加领用人"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <p class="addtakename">
          <span class="name">领用人名称：</span>
          <a-input v-model="takename" placeholder="请输入名称" />
        </p>
      </a-modal>

      <!-- end -->
      <div class="mt20">
        <table class="list wxb-table" style="width: 100%">
          <thead>
            <tr>
              <th>领用人id</th>
              <th>领用人名称</th>
              <th>添加时间</th>
              <th>状态操作</th>
            </tr>
          </thead>
          <tbody class="ft14 ftw400 cl-black" v-if="datas.length > 0">
            <tr v-for="(item, index) in datas" :key="index">
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <div class="flex center cl-deepblue">
                  {{ item.create_time }}
                </div>
              </td>
              <td>
                <div class="flex center alcenter omen-action">
                  <a-dropdown>
                    <div class="flex alcenter">
                      <i class="iconfont iconmore_blue ft24 omen-action-icon" />
                    </div>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a href="javascript:;" @click="editClick(item)">
                          <i class="iconfont iconedit"></i>
                          <span class="ml10">编辑单位</span>
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="removeClick(item.id)">
                          <i class="iconfont icondelete"></i>
                          <span class="ml10">删除单位</span>
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="6">
                <a-empty />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex center mt20">
          <a-pagination
            @change="pageChange"
            :total="total"
            v-model="page"
            :pageSize="pageSize"
            show-less-items
          />
        </div>

        <!-- 编辑弹窗 -->
        <a-modal
          title="编辑领用单位"
          :visible="editvisible"
          @ok="edithandleOk"
          @cancel="edithandleCancel"
        >
          <p class="addtakename">
            <span class="name">领用单位名称：</span>
            <a-input v-model="editname" placeholder="请输入名称" />
          </p>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { listMixin } from '../common/mixin/list.js'
export default {
  mixins: [listMixin],
  data() {
    return {
      datas: [],
      search: {
        name: '',
      },

      visible: false, //添加弹窗
      takename: '', //领货名称

      editvisible: false, //编辑
      editname: '',
      id: '',
    }
  },
  methods: {
    getLists() {
      this.search.name = ''
      this.$http
        .api('admin/userManList', {})
        .then((res) => {
          this.datas = res.list
        })
        .catch((res) => {
          console.log(res)
        })
    },

    //搜索
    searchAct() {
      this.$http
        .api('admin/getReceivingUnitList', {
          name: this.search.name,
        })
        .then((res) => {
          //   console.log(res, '领货列表')
          this.datas = res.list
        })
        .catch((res) => {
          console.log(res)
        })
    },
    //添加
    addClick() {
      this.visible = true
    },

    handleOk(e) {
      console.log(this.takename, 'takename')
      this.$http
        .api('admin/userManCreateRe', {
          name: this.takename,
        })
        .then((res) => {
            console.log(res, '添加')
          this.$message.info(res)
        })
      this.takename = ''
      this.visible = false

      setTimeout(() => {
        this.getLists()
      }, 100)
    },
    handleCancel(e) {
      this.visible = false
    },

    //编辑单位
    editClick(e) {
      console.log(e)
      this.editname = e.name
      this.id = e.id
      this.editvisible = true
    },

    edithandleOk() {
      console.log(this.editname, this.id)
      this.$http
        .api('admin/userManEditRe', {
          name: this.editname,
          id: this.id,
        })
        .then((res) => {
          // console.log(res,"编辑")
          this.$message.info(res)
        })

      this.editvisible = false
      setTimeout(() => {
        this.getLists()
      }, 100)
    },

    edithandleCancel() {
      this.editvisible = false
    },

    // 删除
    removeClick(e) {
      console.log(e)
      this.$confirm({
        title: '确定删除这个单位吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .api('admin/userManEel', {
                id: e,
              })
              .then((res) => {
                this.$message.success('操作成功', 1, () => {
                  setTimeout(() => {
                    this.getLists()
                  }, 100)
                })
              })
              .catch((res) => {
                console.log(res)
              })
              .finally(() => {
                resolve()
              })
          })
        },
      })
    },
  },
}
</script>

<style>
.addtakename {
  display: flex;
  align-items: center;
}
.addtakename .name {
  white-space: nowrap;
}
</style>
