<template>
  <div id="app">
	<a-config-provider :locale="locale" :getPopupContainer="getPopupContainer">
		<router-view></router-view>
	</a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	data(){
		return {
			locale:zhCN
		}
	},
	methods:{
		getPopupContainer(el, dialogContext) {
				  if (dialogContext) {
					return dialogContext.getDialogWrap();
				  } else {
					return document.body;
				  }
				}
	}
}
</script>

<style>

</style>
