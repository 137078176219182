export const showchild = [

];

export const menus = [
	
	{
		name: '账号管理',
		path: '/account',
		icon: 'iconpartner_staff',
		key: 'account', //对应的是 路由的name
		child: [],
	},
	
	{
		name: '库房管理',
		path: '/room',
		icon: 'iconorder_operation_hexiao',
		key: 'room', //对应的是 路由的name
		child: [],
	},

    {
		name: '供货单位',
		path: '/supply',
		icon: 'iconmall_goods',
		key: 'supply', //对应的是 路由的name
		child: [],
	},

    {
		name: '领货单位',
		path: '/takegoods',
		icon: 'iconmall_order',
		key: 'takegoods', //对应的是 路由的name
		child: [],
	},

    {
		name: '领用人列表',
		path: '/usermanlist',
		icon: 'iconmall_order',
		key: 'usermanlist', //对应的是 路由的name
		child: [],
	},
	
	{
		name: '申请列表',
		path: '/apply',
		icon: 'iconhexiao1',
		key: 'apply', //对应的是 路由的name
		child: [],
	},
	
	{
		name: '用户管理',
		path: '/user',
		icon: 'iconpartner_staff',
		key: 'user', //对应的是 路由的name
		child: [],
	},
	
	{
		name: '物资管理',
		path: '/material',
		icon: 'iconmall_goods',
		key: 'material', //对应的是 路由的name
		child: [],
	},
	{
		name: '出入库清单',
		path: '/inoutlist',
		icon: 'iconmall_order',
		key: 'inoutlist', //对应的是 路由的name
		child: [],
	},
	
	{
		name: '物料二维码',
		path: '/qrcode',
		icon: 'iconbtn_card_ma-copy',
		key: 'qrcode', //对应的是 路由的name
		child: [],
	},
	{
		name: '基本设置',
		path: '/setting',
		icon: 'iconadmin',
		key: 'setting', //对应的是 路由的name
		child: [],
	},
	{
		name: '数据中心',
		path: '/index',
		icon: 'iconappoint_tab09',
		key: 'index', //对应的是 路由的name
		child: [],
	},
	
];
