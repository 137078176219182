<template>
	<div>
		<a-modal title="选择库房" :width="800" :visible="visible"  :closable="false"   :footer="null">
			<div>
				<a-spin :spinning="loading">
					<a-input ref="userNameInput" v-model="search.name" placeholder="搜索库房名称" @pressEnter="searchAct">
					      <a-icon slot="prefix" type="search" />
					</a-input>
					
					<div class="mt20 flex wrap center" >
						<table class="list wxb-table" style="width: 100%;">
							<thead>
								<tr>
									<th>库房id</th>
									<th>库房名称</th>
									<th>logo</th>
									<th>库房拥有者</th>
									<th>添加时间</th>
									<th>状态操作</th>
								</tr>
							</thead>
							<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
								<tr v-for="(item,index) in datas">
									<td>
										{{item.room_id}}
									</td>
									<td>
										{{item.name}}
									</td>
									<td>
										<div class="flex center alcenter" >
											<img v-if="item.logo != null" :src="baseUrl+item.logo" class="room-index-table-logo"/>
											<img v-else src="../../../../assets/image/face.png" class="room-index-table-logo"/>
										</div>
									</td>
									
									<td>{{item.bind.admin ? item.bind.admin.username : '-'}}</td>
									<td><div class="flex center cl-deepblue">{{item.add_time_format}}</div></td>
									<td>
										<div class="flex center alcenter " >
											<a-button type="primary" @click="chooseAt(index)">选择</a-button>
										</div>
									</td>
								</tr>
							</tbody>
							
							<tbody v-else>
								<tr>
									<td colspan="6">
										<a-empty/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="flex center mt10">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			value:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				total:0,
				pageSize:5,
				page:1,
				datas:[],
				search:{
					name:'',
				}
				
			}
		},
		created() {
			this.getLists();
		},
		methods:{
			/**
			 * 加载本页数据
			 */
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getRoomList',{
					name:this.search.name,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			chooseAt(index){
				let value=this.datas[index].room_id;
				this.$emit('input',value);
				this.$emit('ok');
			},
			
			
			/**
			 * 分页的跳转
			 * @param {Object} e
			 */
			pageChange(e){
			    this.page = parseInt(e);
			    this.getLists();
			},
			
			/**
			 * 查询的操作
			 */
			searchAct(){
			    this.page = 1;
			    this.getLists();
			},
			
			/**
			 * 取消查询的操作
			 */
			cancelAct(){
			    this.page = 1;
			    this.searchCancel();
			    this.getLists();
			},
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					name:'',
				}
			},
		}
	}
</script>

<style>
.room-index-table-logo{
		width: 48px;
		height: 48px;
	}
</style>
