<template>
	<div>
		<div class="bg-w pd20" style="min-height: 950px;">
			<a-spin :spinning="loading">
				
				<div class="form-seach-box ">
					<a-form layout="inline">
						<a-form-item label="昵称">
							<a-input v-model="search.nick_name" placeholder="请输入昵称"></a-input>
						</a-form-item>
						
						<a-form-item label="手机号">
							<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
						</a-form-item>
						
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
				
				<div class="mt20">
					<table class="list wxb-table" style="width: 100%;">
						<thead>
							<tr>
								<th>头像</th>
								<th>昵称</th>
								<th>手机号</th>
								<th>添加时间</th>
								<th>操作</th>
							</tr>
						</thead>
						<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
							<tr v-for="(item,index) in datas">
								<td>
									<div class="flex center alcenter" >
										<img v-if="item.face != null" :src="item.face" class="account-index-table-face"/>
										<img v-else src="../assets/image/face.png" class="account-index-table-face"/>
									</div>
								</td>
								
								<td>{{item.nick_name ? item.nick_name : '-' }}</td>
								<td>
									{{item.mobile}}
								</td>
								<td><div>{{item.add_time_format}}</div></td>
								<td>
									<div class="flex center alcenter omen-action" >
										<a-dropdown>
											<div class="flex alcenter" >
												<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
											</div>
											<a-menu slot="overlay">
												<a-menu-item v-if="adminInfo.role_id==2">
													<a href="javascript:;"  @click="assignJurisdictionAct(index)">
														<i class="iconfont iconappoint_tab12"></i>
														<span class="ml10">分配权限</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="adminInfo.role_id==1">
													<a href="javascript:;" @click="userRoomAct(index)">
														<i class="iconfont iconshop"></i>
														<span class="ml10">查看库房</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="adminInfo.role_id==2">
													<a href="javascript:;" @click="delBindAct(index)">
														<i class="iconfont icondelete"></i>
														<span class="ml10">解绑</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</td>
							</tr>
						</tbody>
						
						<tbody v-else>
							<tr>
								<td colspan="5">
									<a-empty/>
								</td>
							</tr>
						</tbody>
					</table>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					<div v-if="userRoomVisible">
						<user-room :visible="userRoomVisible" :user_id="user_id" @cancel="cancelUserRoom"></user-room>
					</div>
					<div v-if="assignJurisdictionVisible">
						<assign-jurisdiction :visible="assignJurisdictionVisible" :user="user"  
						@cancel="cancelAssignJurisdiction" @ok="okAssignJurisdiction"></assign-jurisdiction>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../common/mixin/list.js';
	import userRoom from './components/user/modal/userRoom.vue';
	import assignJurisdiction from './components/user/modal/assignJurisdiction.vue';
	export default{
		mixins:[listMixin],
		components:{
			userRoom,
			assignJurisdiction,
		},
		data(){
			return{
				loading:false,
				userRoomVisible:false,
				assignJurisdictionVisible:false,
				user_id:0,
				user:{},
				datas:[],
				search:{
					nick_name:'',
					mobile:'',
				}
			}
		},
		created() {
			
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getUserList',{
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			//查看库房
			userRoomAct(index){
				this.user_id=this.datas[index].user_id;
				this.userRoomVisible=true;
			},
			cancelUserRoom(){
				this.userRoomVisible=false;
			},
			
			//分配权限
			assignJurisdictionAct(index){
				this.user=this.datas[index];
				this.assignJurisdictionVisible=true;
			},
			cancelAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
			},
			okAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
				this.getLists();
			},
			
			delBindAct(index){
				this.$confirm({
					title: '确定解绑吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delUserRoomFromTwo', {
								user_id: this.datas[index].user_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					username:'',
				}
			},
		}
	}
</script>

<style>
	.account-index-table-face{
		width: 48px;
		height: 48px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}
</style>
