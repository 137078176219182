<template>
  <div>
    <div class="bg-w pd20" style="min-height: 950px">
      <a-spin :spinning="loading">
        <div class="flex alcenter">
          <a-button type="primary" class="mr10" @click="exportExcel()"
            >导出清单</a-button
          >
        </div>
        <div class="form-seach-box mt20">
          <a-form layout="inline">
            <a-form-item label="选择日期">
              <!-- <a-date-picker v-model="search.day" valueFormat="YYYY-MM-DD"/> -->
              <a-range-picker
                v-model="search.day"
                @change="onChange"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>

            <a-form-item label="物资名称">
              <a-input
                v-model="search.material_name"
                placeholder="请输入物资名称"
              ></a-input>
            </a-form-item>

            <a-form-item label="库房">
              <a-input
                v-model="search.room_name"
                placeholder="请输入库房"
              ></a-input>
            </a-form-item>

            <a-form-item label="领货单位">
              <a-select
                placeholder="请选择领货单位"
                v-model="search.receiving_unit"
                style="width: 200px"
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index1) in takelist"
                  :key="index1"
                  :value="index1"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="供货单位">
              <a-select
                placeholder="请选择供货单位"
                v-model="search.supplier"
                style="width: 200px"
                @change="handleChange2"
              >
                <a-select-option
                  v-for="(item, index) in supplierlist"
                  :key="index"
                  :value="index"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="操作类型">
              <a-select v-model="search.type" style="width: 200px">
                <a-select-option :value="0"> 请选择 </a-select-option>
                <a-select-option :value="1"> 出库 </a-select-option>
                <a-select-option :value="2"> 入库 </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item>
              <a-button @click="searchAct" type="primary">查询</a-button>
              <a-button @click="cancelAct" class="ml10">取消</a-button>
            </a-form-item>
          </a-form>
        </div>

        <div class="mt20">
          <table class="list wxb-table" style="width: 100%">
            <thead>
              <tr>
                <th>所属库房</th>
                <th>物资</th>
                <th>规格型号</th>
                <th>备件编号</th>
                <th>操作类型</th>
                <th>领用单位</th>
                <th>领用人</th>
                <th>供货单位</th>
                
                <th>备注</th>
                <th>数量</th>
                <th>操作人</th>
                <th>操作时间</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody class="ft14 ftw400 cl-black" v-if="datas.length > 0">
              <tr v-for="(item, index) in datas" :key="index">
                <td>
                  {{ item.room.name }}
                </td>
                <td>
                  {{ item.material.name }}
                </td>
                <td>{{ item.specifications }}</td>
                <td>
                  {{ item.spare_number }}
                </td>
                <td>{{ item.type_mean }}</td>
                <td>{{ item.receiving_name ? item.receiving_name : '-' }}</td>
                <td>{{item.user_man_name?item.user_man_name:'-'}}</td>
                <td>{{ item.supplier_name ? item.supplier_name : '-' }}</td>
                <td>{{ item.remark ? item.remark : '-' }}</td>
                <td>{{ item.num }}</td>
                <td>
                  {{ item.operator_name }}
                </td>
                <td>
                  {{ item.add_time_format }}
                </td>
                <td>
                  <div class="flex center alcenter omen-action">
                    <a-dropdown>
                      <div class="flex alcenter">
                        <i
                          class="iconfont iconmore_blue ft24 omen-action-icon"
                        />
                      </div>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="delInoutlistAct(index)"
                          >
                            <i class="iconfont icondelete"></i>
                            <span class="ml10">删除记录</span>
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr>
                <td colspan="12">
                  <a-empty />
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex center mt20">
            <a-pagination
              @change="pageChange"
              :total="total"
              v-model="page"
              :pageSize="pageSize"
              show-less-items
            />
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { listMixin } from '../common/mixin/list.js'
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      datas: [],
      search: {
        material_name: '',
        room_name: '',
        type: 0,
        day: [],
        supplier: undefined,
        receiving_unit: undefined,
      },
      receiving_unit: '',
      supplier: '',
      takelist: [], //领货单位
      supplierlist: [], //供货单位
    }
  },
  created() {
    this.getreceivingAll()
    this.getsupplierAll()
  },
  methods: {
    getLists() {
      if (this.loading == true) return
      this.loading = true
      
      this.$http
        .api('admin/getInoutlist', {
          material_name: this.search.material_name,
          room_name: this.search.room_name,
          code: this.search.code,
          type: this.search.type,
          receiving_unit: this.receiving_unit,
          supplier: this.supplier,
          start: this.search.day[0],
          end: this.search.day[1],
          limit: this.pageSize,
          page: this.page,
        })
        .then((res) => {
          this.total = res.total
          this.datas = res.list
          this.loading = false
          
        })
        .catch((res) => {
          console.log(res)
          this.loading = false
        })
    },

    //导出物资
    exportExcel() {
      // if(this.loading==true) return;
      // this.loading=true;
      // this.$http.api('admin/exportMaterialexcel',{
      // 	name:this.search.name,
      // 	room_name:this.search.room_name,
      // 	code:this.search.code,
      // 	spare_number:this.search.spare_number,
      // }).then(res=>{

      // 	this.loading=false;
      // }).catch(res=>{
      // 	console.log(res);
      // 	this.loading=false;
      // })

      let href =
        process.env.VUE_APP_BASE_API +
        '/admin/exportInoutlistexcel?auth_token=' +
        encodeURIComponent(localStorage.getItem('storeroom-token'))
      if (this.search.material_name != '') {
        href =
          href +
          '&material_name=' +
          encodeURIComponent(this.search.material_name)
      }
      if (this.search.room_name != '') {
        href = href + '&room_name=' + encodeURIComponent(this.search.room_name)
      }

      if (this.search.type != '') {
        href = href + '&type=' + encodeURIComponent(this.search.type)
      }

      if (this.search.day != '') {
        href = href + '&day=' + encodeURIComponent(this.search.day) + '&start=' +  encodeURIComponent(this.search.day[0]) + '&end=' + encodeURIComponent(this.search.day[1])
      }

      if(this.supplier != ''){
           href = href + '&supplier=' + encodeURIComponent(this.supplier)
      }

       if(this.receiving_unit != ''){
           href = href + '&receiving_unit=' + encodeURIComponent(this.receiving_unit)
      }
    
      window.location.href = href
    },

    delInoutlistAct(index) {
      this.$confirm({
        title: '确定删除这个记录吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .api('admin/delInoutlist', {
                list_id: this.datas[index].list_id,
              })
              .then((res) => {
                this.$message.success('操作成功', 1, () => {
                  this.getLists()
                })
              })
              .catch((res) => {
                console.log(res)
              })
              .finally(() => {
                resolve()
              })
          })
        },
      })
    },

    searchCancel() {
      //改变搜索的表单的初始值
      this.search = {
        material_name: '',
        room_name: '',
        type: 0,
        day: '',
        supplier: undefined,
        receiving_unit: undefined,
      }
      this. receiving_unit=''
      this.supplier = ''
      
      
    },

    // 时间选择
    onChange(date, dateString) {
      //   console.log(date, dateString)
    },

    // 领货单位
    getreceivingAll() {
      this.$http.api('admin/receivingAll', {}).then((res) => {
        // console.log(res, '领货单位')
        this.takelist = res
      })
    },
    //供货单位
    getsupplierAll() {
      this.$http.api('admin/supplierAll', {}).then((res) => {
        // console.log(res, '领货单位')
        this.supplierlist = res
      })
    },
    handleChange(value) {
      console.log(value, this.takelist[value].id)
      this.receiving_unit = this.takelist[value].id
    },
    handleChange2(value) {
      this.supplier = this.supplierlist[value].id
    },
  },
}
</script>

<style>
</style>
