export const listMixin = {
    data(){
        return {
            page:1,
            total:0,
            pageSize:10,
            loading:false,
            datas:[],
            selectedRowKeys:[],
        }
    },
    created(){
        this.getLists();
    },
    methods:{
        pageChange(e){
            this.page = parseInt(e);
            this.getLists();
        },
        searchAct(){
            this.page = 1;
            this.getLists();
        },
        cancelAct(){
            this.page = 1;
            this.searchCancel();
            this.getLists();
        },
        //列表选中时操作
        onSelectChange (selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        searchCancel(){
            //改变搜索的表单的初始值
        },
        getLists(){
            let that = this
            that.loading = true;

            setTimeout(()=>{
                that.loading = false;
            },2000);
        },
		// changeRadio(){
		// 	this.page=1;
		// 	this.getLists()
		// },
    }
}