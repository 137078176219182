<template>
	<div>
		<a-modal title="添加账号" :width="480" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
					    <a-form-model-item label="账号">
					      <a-input    placeholder="请输入账号" v-model="form.username" :maxLength="20"/>
					    </a-form-model-item>
						
						<a-form-model-item label="密码">
						  <a-input type="password"    placeholder="请输入密码" v-model="form.password" />
						</a-form-model-item>
						
						<a-form-model-item label="生成密码">
						  <div class="flex alcenter ">
						  	<div class="">
						  		<a-button type="primary" @click="addPassword()">生成密码</a-button>
						  	</div>
						  	<div class="ml10 flex " v-if="add_password != ''">
						  		{{add_password}}
						  	</div>
						  </div>
						</a-form-model-item>
					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props:{
			visible:{
				type:Boolean,
				default:false,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				add_password:'',
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
				form: {
					username: '',
					password: '',
				}
			}
		},
		methods: {
			addPassword(){
				this.add_password=this.passwords(16);
				this.form.password=this.add_password;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/addAccount', {
					username: this.form.username,
					password: this.form.password,
				}).then(res => {
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading = false;
						this.$emit("ok");
					});
					
				}).catch(res => {
					this.confirmLoading = false;
					console.log(res);
				})


			},
			
			 passwords(pasLen) {
				var pasArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','_','-','$','%','&','@','+','!'];
				var password = '';
				var pasArrLen = pasArr.length;
				for (var i=0; i<pasLen; i++){
				var x = Math.floor(Math.random()*pasArrLen);
					password += pasArr[x];
				}
				return password;
			}
		}
	}
</script>

<style>
	.input-box .ant-input-affix-wrapper .ant-input {
		padding-left: 60px;
	}
</style>
