<template>
	<div>
		<div class="bg-w pd20" style="min-height: 950px;">
			<a-spin :spinning="loading">
				<div class="form-seach-box mt20">
					<a-form layout="inline">
						<a-form-item label="状态">
							<a-select v-model="search.status" style="width: 200px">
								<a-select-option :value="0">
									待处理
								</a-select-option>
								<a-select-option :value="1">
									已通过
								</a-select-option>
								<a-select-option :value="-1">
									已拒绝
								</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
				<div class="mt20">
					<table class="list wxb-table" style="width: 100%;">
						<thead>
							<tr>
								<th>申请人</th>
								<th>申请库房</th>
								<th>状态</th>
								<th>添加时间</th>
								<th>操作</th>
							</tr>
						</thead>
						<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
							<tr v-for="(item,index) in datas" :key="index">
								<td>
									{{item.user?item.user.nick_name:'-'}}
								</td>
								<td>
									{{item.room.name}}
								</td>
								<td>
									{{item.status_mean}}
								</td>
								<td><div class="flex center cl-deepblue">{{item.add_time_format}}</div></td>
								<td>
									<div class="flex center alcenter omen-action" v-if="item.status==0">
										<a-dropdown>
											<div class="flex alcenter" >
												<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
											</div>
											<a-menu slot="overlay">
												<a-menu-item >
													<a href="javascript:;" @click="changeApplyStatusAct(index,1)">
														<i class="iconfont iconyes"></i>
														<span class="ml10">同意</span>
													</a>
												</a-menu-item>
												
												<a-menu-item >
													<a href="javascript:;" @click="changeApplyStatusAct(index,-1)">
														<i class="iconfont iconno"></i>
														<span class="ml10">拒绝</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
									<div v-else class="flex center">-</div>
								</td>
							</tr>
						</tbody>
						
						<tbody v-else>
							<tr>
								<td colspan="5">
									<a-empty/>
								</td>
							</tr>
						</tbody>
					</table>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					<div v-if="assignJurisdictionVisible">
						<assign-jurisdiction :visible="assignJurisdictionVisible" :apply="apply" 
						@cancel="cancelAssignJurisdiction" @ok="okAssignJurisdiction"></assign-jurisdiction>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../common/mixin/list.js';
	import assignJurisdiction from './components/apply/modal/assignJurisdiction.vue';
	export default{
		mixins:[listMixin],
		components:{
			assignJurisdiction,
		},
		data(){
			return{
				loading:false,
				assignJurisdictionVisible:false,
				search:{
					status:0,
				},
				datas:[],
				apply:{},
				
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getApplylist',{
					status:this.search.status,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			changeApplyStatusAct(index,status){
				if(status==1){
					this.apply=this.datas[index];
					this.assignJurisdictionVisible=true;
				}else{
					this.$confirm({
						title: '确定拒绝？',
						okText: '确定',
						okType: 'danger',
						cancelText: '取消',
						onOk: () => {
							return new Promise((resolve,reject)=>{
								this.$http.api('admin/changeApplyStatus', {
									status:status,
									apply_id:this.datas[index].apply_id,
								}).then(res => {
									this.$message.success('操作成功',1,()=>{
										this.getLists(); 
									});
									
								}).catch(res => {
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
							
						},
					});
				}
			},
			cancelAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
			},
			okAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
				this.getLists();
			},
			
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					status:0,
				}
			},
		}
	}
</script>

<style>
</style>
