const  router = [
	{
		path: "/qrcode",
		component: () => import('../../../pages/qrcode.vue'),
		meta: {title: '物料二维码'},
		name: 'qrcode',
		pname:'qrcode',
	},
];

export default router