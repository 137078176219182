<template>
	<div>
		<div class="bg-w pd20" style="min-height: 800px;">
			<div class="mt40" v-if="adminInfo.room_id!=0">
				<a-spin :spinning="loading">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="网站地址" required>
							<a-input    placeholder="请输入网站地址" v-model="form.site_url" style="width: 400px;" />
						</a-form-model-item>
					
						<a-form-model-item label="出库数量设置" required>
							<a-input-number :formatter="value => `${value} 个`" v-model="form.delivery_warnning" :min="0" :precision="0"
							 placeholder="请输入出库数量设置" style="width: 200px;"/>
						</a-form-model-item>
						
						<a-form-model-item label="库存预警" required>
							<a-input-number :formatter="value => `${value} 个`" v-model="form.quota_warnning" :min="0" :precision="0"
							 placeholder="请输入库存预警" style="width: 200px;" />
						</a-form-model-item>
						
						<a-form-model-item label="占地库存" required>
							<a-input-number :formatter="value => `${value} 个`" v-model="form.occupied_num" :min="0" :precision="0"
							 placeholder="请输入占地库存"  style="width: 200px;"/>
						</a-form-model-item>
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
							<a-button type="primary" @click="onSubmit" @loading="confirmLoading">
								保存设置
							</a-button>
						</a-form-model-item>
					</a-form-model>
				</a-spin>
			</div>
			
			<div class="mt40 flex center" v-else>
				<a-empty description="您还没自己的库房"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				loading:false,
				confirmLoading:false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				form:{
					site_url:'',
					delivery_warnning:0,
					quota_warnning:0,
					occupied_num:0,
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/showSetting', {
					
				}).then(res => {
					this.form = res.detail;
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			onSubmit(){
				this.confirmLoading = true;
				this.$http.api('admin/saveSetting', {
					site_url: this.form.site_url,
					delivery_warnning: this.form.delivery_warnning,
					quota_warnning: this.form.quota_warnning,
					occupied_num: this.form.occupied_num,
				}).then(res => {
					this.$message.success('操作成功', 1, () => {
						this.confirmLoading = false;
						this.loaddata();
					});
				
				}).catch(res => {
					this.confirmLoading = false;
					console.log(res);
				})
			}
		}
	}
</script>

<style>
</style>
