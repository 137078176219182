<template>
	<div>
		<a-modal title="查看库房" :width="600" :visible="visible"   @cancel="handleCancel" :footer="null">
			<div>
				<a-spin :spinning="loading">
					<div class="mb20 flex alcenter center">
						<div class="flex alcenter">
							<div class="ft16 ftw400 cl-info">昵称：</div>
							<div class="ft16 ftw400 cl-black">{{user.nick_name}}</div>
						</div>
						<div class="flex alcenter ml30">
							<div class="ft14 ftw400 cl-info">手机号：</div>
							<div class="ft14 ftw400 cl-black">{{user.mobile}}</div>
						</div>
					</div>
					
					<a-input ref="userNameInput" v-model="search.keyword" placeholder="搜索库房名称" @pressEnter="searchAct">
					      <a-icon slot="prefix" type="search" />
					</a-input>
					
					<div class="mt20 flex wrap center" >
						<table class="list wxb-table" style="width: 100%;">
							<thead>
								<tr>
									<th>库房名称</th>
									<th>logo</th>
									<th>权限</th>
									<th>操作</th>
								</tr>
							</thead>
							<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
								<tr v-for="(item,index) in datas">
									<td>
										{{item.room.name}}
									</td>
									<td>
										<div class="flex center alcenter" >
											<img v-if="item.room.logo != null" :src="baseUrl+item.room.logo" class="user-room-logo"/>
											<img v-else src="../../../../assets/image/logo_yilianyun@3x.png" class="user-room-logo"/>
										</div>
									</td>
									<td>{{item.jurisdiction_mean}}</td>
									<td>
										<div class="flex center alcenter omen-action" >
											<a-dropdown>
												<div class="flex alcenter" >
													<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
												</div>
												<a-menu slot="overlay">
													<a-menu-item >
														<a href="javascript:;" @click="assignJurisdictionAct(index)">
															<i class="iconfont iconappoint_tab12"></i>
															<span class="ml10">分配权限</span>
														</a>
													</a-menu-item>
													<a-menu-item >
														<a href="javascript:;" @click="delBindAct(index)">
															<i class="iconfont icondelete"></i>
															<span class="ml10">解绑</span>
														</a>
													</a-menu-item>
												</a-menu>
											</a-dropdown>
										</div>
									</td>
								</tr>
							</tbody>
							
							<tbody v-else>
								<tr>
									<td colspan="4">
										<a-empty/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="flex center mt10">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					<div v-if="assignJurisdictionVisible">
						<assign-jurisdiction :visible="assignJurisdictionVisible" :user="user" :bind="bind" 
						@cancel="cancelAssignJurisdiction" @ok="okAssignJurisdiction"></assign-jurisdiction>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import assignJurisdiction from './userRoom/modal/assignJurisdiction.vue';
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			user_id:{
				type:Number,
				default:0
			}
		},
		
		components:{
			assignJurisdiction
		},
		
		data(){
			return{
				loading:false,
				confirmLoading:false,
				assignJurisdictionVisible:false,
				total:0,
				pageSize:5,
				page:1,
				bind:{},
				datas:[],
				user:{
					user_id:0,
					nick_name:'',
					mobile:'',
				},
				search:{
					name:'',
				}
				
			}
		},
		created() {
			this.getLists();
		},
		methods:{
			/**
			 * 加载本页数据
			 */
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api("admin/getUserRoomList",{
					user_id:this.user_id,
					limit:this.pageSize,
					page:this.page,
					name:this.search.name,
				}).then(res=>{
					this.datas=res.list;
					this.total=res.total;
					this.user=res.user;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
					this.$emit('cancel');
				})
			},
			
			delBindAct(index){
				this.$confirm({
					title: '确定解绑给这个关系吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delUserRoomFromOne', {
								bind_id: this.datas[index].bind_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			assignJurisdictionAct(index){
				this.bind=this.datas[index];
				this.assignJurisdictionVisible=true;
			},
			cancelAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
			},
			okAssignJurisdiction(){
				this.assignJurisdictionVisible=false;
				this.getLists();
			},
			
			/**
			 * 给父组件发出关闭弹窗的指令
			 */
			handleCancel(){
				this.$emit('cancel');
			},
			
			/**
			 * 分页的跳转
			 * @param {Object} e
			 */
			pageChange(e){
			    this.page = parseInt(e);
			    this.getLists();
			},
			
			/**
			 * 查询的操作
			 */
			searchAct(){
			    this.page = 1;
			    this.getLists();
			},
			
			/**
			 * 取消查询的操作
			 */
			cancelAct(){
			    this.page = 1;
			    this.searchCancel();
			    this.getLists();
			},
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					name:'',
				}
			},
		}
	}
</script>

<style>
	.user-room-logo{
		width: 48px;
		height: 48px;
	}
</style>
