<template>
	<div>
		<div class="bg-w pd20" style="min-height: 950px;">
			<a-spin :spinning="loading">
				<div class="form-seach-box ">
					<a-form layout="inline">
						<a-form-item label="库房名称">
							<a-input v-model="search.name" placeholder="请输入库房名称"></a-input>
						</a-form-item>
						
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
				
				<div class="mt20">
					<table class="list wxb-table" style="width: 100%;">
						<thead>
							<tr>
								<th>库房id</th>
								<th>库房名称</th>
								<th>logo</th>
								<th>库房二维码</th>
								<th>库房拥有者</th>
								<th>添加时间</th>
								<th>状态操作</th>
							</tr>
						</thead>
						<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
							<tr v-for="(item,index) in datas">
								<td>
									{{item.room_id}}
								</td>
								<td>
									{{item.name}}
								</td>
								<td>
									<div class="flex center alcenter" >
										<img v-if="item.logo != null" :src="baseUrl+item.logo" class="room-index-table-logo"/>
										<img v-else src="../assets/image/face.png" class="room-index-table-logo"/>
									</div>
								</td>
								
								<td>
									<div class="flex center alcenter" v-if="item.qrcode">
										<show-pic v-model="item.qrcode"></show-pic>
									</div>
									<div class="flex center alcenter" v-else>
										<a-button type="primary" @click="createQrcodeAct(index)">生成二维码</a-button>
									</div>
								</td>
								
								<td>{{item.bind.admin ? item.bind.admin.username : '-'}}</td>
								<td><div class="flex center cl-deepblue">{{item.add_time_format}}</div></td>
								<td>
									<div class="flex center alcenter omen-action" >
										<a-dropdown>
											<div class="flex alcenter" >
												<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
											</div>
											<a-menu slot="overlay">
												<a-menu-item v-if="adminInfo.role_id==1">
													<a href="javascript:;" @click="roomAdminAct(index)">
														<i class="iconfont iconappoint_tab05"></i>
														<span class="ml10">查看管理</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="adminInfo.role_id==2 && (item.room_id != adminInfo.room_id)">
													<a href="javascript:;" @click="delBindAct(index)">
														<i class="iconfont icondelete"></i>
														<span class="ml10">解绑</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</td>
							</tr>
						</tbody>
						
						<tbody v-else>
							<tr>
								<td colspan="6">
									<a-empty/>
								</td>
							</tr>
						</tbody>
					</table>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					<div v-if="roomAdminVisible">
						<room-admin :visible="roomAdminVisible" :room_id="room_id" @cancel="cancelRoomAdmin"></room-admin>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../common/mixin/list.js';
	import roomAdmin from './components/room/modal/roomAdmin.vue';
	import showPic from '../components/photo/showPic.vue';
	export default{
		mixins:[listMixin],
		components:{
			roomAdmin,
			showPic
		},
		data(){
			return{
				loading:false,
				roomAdminVisible:false,
				datas:[],
				room_id:0,
				room:{},
				search:{
					name:'',
				}
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getRoomList',{
					name:this.search.name,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			roomAdminAct(index){
				this.room_id=this.datas[index].room_id;
				this.roomAdminVisible=true;
			},
			cancelRoomAdmin(){
				this.roomAdminVisible=false;
			},
			
			delBindAct(index){
				this.$confirm({
					title: '确定解绑吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delRoomAdminFromTwo', {
								room_id: this.datas[index].room_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			createQrcodeAct(index){
				this.$confirm({
					title: '确定生成二维码吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/createRoomQrcode', {
								room_id: this.datas[index].room_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					name:'',
				}
			},
		}
	}
</script>

<style>
	.room-index-table-logo{
		width: 48px;
		height: 48px;
	}
</style>
