<template>
	<div>
		<a-modal title="分配权限" :width="480" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel"
			@ok="onSubmit">
			<a-spin :spinning="loading">
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="管理员">
							<div class="ft16 ftw600 cl-main">{{admin.username}}</div>
						</a-form-model-item>

						<a-form-model-item label="库房">
							<div class="ft16 ftw600 cl-main">{{bind.room.name}}</div>
						</a-form-model-item>

						<a-form-model-item label="选择权限">
							<a-select v-model="form.jurisdiction" style="width: 200px">
								<a-select-option :value="0">
									没有权限
								</a-select-option>
								<a-select-option :value="1">
									出库
								</a-select-option>
								<a-select-option :value="2">
									入库
								</a-select-option>
								<a-select-option :value="3">
									出入库
								</a-select-option>
							</a-select>
						</a-form-model-item>

					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			admin: {
				type: Object,
				default: function() {
					return new Object;
				}
			},
			bind: {
				type: Object,
				default: function() {
					return new Object;
				}
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				form:{
					jurisdiction: 0,
				}
				
			}
		},
		created() {
			this.form.jurisdiction = this.bind.jurisdiction;
		},

		methods: {




			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/assignJurisdictionFromOne', {
					bind_id: this.bind.bind_id,
					jurisdiction: this.form.jurisdiction,
				}).then(res => {
					this.$message.success('操作成功', 1, () => {
						this.confirmLoading = false;
						this.$emit("ok");
					});

				}).catch(res => {
					this.confirmLoading = false;
					console.log(res);
				})


			},


		}
	}
</script>

<style>
	.input-box .ant-input-affix-wrapper .ant-input {
		padding-left: 60px;
	}
</style>
