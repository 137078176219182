import {mapState} from 'vuex';
export const adminInfo = {
    computed :{
		...mapState(['adminInfo','baseUrl']),
	},
    methods:{
        setAdminInfo(adminInfo){
			this.$store.commit('setAdminInfo',adminInfo);
		}
    }
}