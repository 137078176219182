<template>
	<div>
		<a-modal :title="getTitle" :width="480" :visible="visible" :confirm-loading="confirmLoading"
			@cancel="handleCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="选择库房" v-if="adminInfo.role_id == 1 && material_id==0">
							<a-select v-model="form.room_id" style="width: 200px">
								<a-select-option :value="0">
									请选择
								</a-select-option>
								<a-select-option :value="item.room_id" v-for="(item,index) in room_list" :key="index">
									{{item.name}}
								</a-select-option>

							</a-select>
						</a-form-model-item>

						<a-form-model-item label="物资名称">
							<a-input placeholder="请输入物资名称" v-model="form.name" />
						</a-form-model-item>

						<a-form-model-item label="编码">
							<a-input placeholder="请输入编码" v-model="form.code" />
						</a-form-model-item>

						<a-form-model-item label="规格型号">
							<a-input placeholder="请输入规格型号" v-model="form.specifications" />
						</a-form-model-item>

						<a-form-model-item label="备件编号">
							<a-input placeholder="请输入备件编号" v-model="form.spare_number" />
						</a-form-model-item>

						<a-form-model-item label="基本单位">
							<a-input placeholder="请输入基本单位" v-model="form.basic_unit" />
						</a-form-model-item>

						<a-form-model-item label="仓库位置">
							<a-input placeholder="请输入仓库位置" v-model="form.warehouse_location" />
						</a-form-model-item>

						<a-form-model-item label="单价">
							<a-input-number :formatter="value => `${value} 元`" v-model="form.unit_price" :min="0"
								:precision="2" placeholder="请输入单价" />
						</a-form-model-item>

						<a-form-model-item label="库存">
							<a-input-number :formatter="value => `${value} 个`" v-model="form.quota" :min="1"
								:precision="0" placeholder="请输入库存" />
						</a-form-model-item>

						<a-form-model-item label="库存预警">
							<a-input-number :formatter="value => `${value} 个`" v-model="form.quota_warning" :min="1"
								:precision="0" placeholder="请输入库存预警" />
						</a-form-model-item>

						<a-form-model-item label="备注">
							<a-input placeholder="请输入备注" v-model="form.remarks" />
						</a-form-model-item>

						

					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			material_id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				room_list: [],
				form: {
					room_id: 0,
					name: '',
					code: '',
					specifications: '',
					spare_number: '',
					basic_unit: '',
					warehouse_location: '',
					unit_price: '',
					quota: '',
					quota_warning: '',
					remarks: '',
				}
			}
		},
		computed: {
			getTitle() {
				if (this.material_id == 0) {
					return '添加物资';
				} else {
					return '编辑物资'
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/showMaterial', {
					material_id: this.material_id,
				}).then(res => {
					if (res.detail) {
						this.form = res.detail;
					}
					if (res.room_list) {
						this.room_list = res.room_list;
					}
					this.loading = false;
				}).catch(res => {
					this.loading = false;
					this.$emit('cancel');
				})
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/saveMaterial', {
					material_id: this.material_id,
					room_id: this.form.room_id,
					name: this.form.name,
					code: this.form.code,
					specifications: this.form.specifications,
					spare_number: this.form.spare_number,
					basic_unit: this.form.basic_unit,
					warehouse_location: this.form.warehouse_location,
					unit_price: this.form.unit_price,
					quota: this.form.quota,
					quota_warning: this.form.quota_warning,
					remarks: this.form.remarks,
				}).then(res => {
					this.$message.success('操作成功', 1, () => {
						this.confirmLoading = false;
						this.$emit("ok");
					});

				}).catch(res => {
					this.confirmLoading = false;
					console.log(res);
				})


			},

		}
	}
</script>

<style>

</style>
