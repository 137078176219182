<template>
  <div>
    <a-modal
      :title="getTitle"
      :width="480"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="onSubmit"
    >
      <a-spin :spinning="loading">
        <div>
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="领用单位" v-if="type == 1">
              <a-select
                placeholder="请选择领用单位"
                v-model="form.receiving_unit"
                style="width: 200px"
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index1) in takelist"
                  :key="index1"
                  :value="index1"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="供货单位" v-if="type == 2">
              <a-select
                placeholder="请选择供货单位"
                v-model="form.supplier"
                style="width: 200px"
                @change="handleChange2"
              >
                <a-select-option
                  v-for="(item, index) in supplierlist"
                  :key="index"
                  :value="index"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="数量">
              <a-input-number
                :formatter="(value) => `${value} 个`"
                v-model="form.num"
                :min="1"
                :precision="0"
                placeholder="请输入数量"
              />
            </a-form-model-item>

            <a-form-model-item label="领用人" v-if="type == 1">
              <a-select
                placeholder="请选择领用人"
                v-model="form.userman"
                style="width: 200px"
                @change="handleChangeuser"
              >
                <a-select-option
                  v-for="(item, index1) in userlist"
                  :key="index1"
                  :value="index1"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="备注">
              <a-input placeholder="请输入备注" v-model="form.remark" />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    material_id: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      room_list: [],
      form: {
        receiving_unit: undefined,
        supplier: undefined,
        num: 1,
        remark: '',
        userman: '',
      },

      receiving_unit: '',
      supplier: '',
      userman:'',
      takelist: [], //领货单位
      supplierlist: [], //供货单位
      userlist:[],//领用人列表
    }
  },
  computed: {
    getTitle() {
      if (this.type == 1) {
        return '出库'
      } else {
        return '入库'
      }
    },
  },
  created() {
    this.getreceivingAll()
    this.getsupplierAll()
    this.getusermanlist()
  },
  methods: {
    /**
     * 取消弹窗
     */
    handleCancel() {
      this.$emit('cancel')
    },
    //领用人列表
    getusermanlist(){
         this.$http.api('admin/userManAll', {}).then((res) => {
        // console.log(res, '领货单位')
        this.userlist = res
      })
    },

    /**
     * 提交数据
     */
    onSubmit() {
      if (this.confirmLoading == true) return
      this.confirmLoading = true
      this.$http
        .api('admin/materialInOut', {
          material_id: this.material_id,
          type: this.type,
          supplier: this.supplier,
          receiving_unit: this.receiving_unit,
          remark: this.form.remark,
          num: this.form.num,
          user_man: this.userman,
        })
        .then((res) => {
          this.$message.success('操作成功', 1, () => {
            this.confirmLoading = false
            this.$emit('ok')
          })
        })
        .catch((res) => {
          this.confirmLoading = false
          console.log(res)
        })
    },

    // 领货单位
    getreceivingAll() {
      this.$http.api('admin/receivingAll', {}).then((res) => {
        // console.log(res, '领货单位')
        this.takelist = res
      })
    },
    //供货单位
    getsupplierAll() {
      this.$http.api('admin/supplierAll', {}).then((res) => {
        // console.log(res, '领货单位')
        this.supplierlist = res
      })
    },
    handleChange(value) {
      this.receiving_unit = this.takelist[value].id
    },
    handleChange2(value) {
      this.supplier = this.supplierlist[value].id
     
    },
    handleChangeuser(value){
        this.userman = this.userlist[value].id
    }
  },
}
</script>

<style>
</style>
