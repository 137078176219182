<template>
	<div>
		<img :src="baseUrl+value" class="show-pic clickAct" @click="showAct()"/>
		<div v-if="showPicVisible" class="show-pic-modal">
			<div class="flex alcenter center" style="height: 100%;" @click="closeAct()">
				<img :src="baseUrl+value"  class="show-pic-modal-photo"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			value:{
				type:String,
				default:'',
			}
		},
		data(){
			return{
				showPicVisible:false,
			}
		},
		methods:{
			showAct(){
				console.log(1);
				this.showPicVisible=true;
			},
			closeAct(){
				this.showPicVisible=false;
			}
		}
	}
</script>

<style>
	.show-pic{
		width: 48px;
		height: 48px;
	}
	
	.show-pic-modal{
		position: fixed;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: 1000;
		    height: 100%;
		    background-color: rgba(0,0,0,.45);
	}
	
	.show-pic-modal-photo{
		width: 430px;
		height: 430px;
		z-index: 1001;
	}
</style>
