const  router = [
	{
		path: "/user",
		component: () => import('../../../pages/user.vue'),
		meta: {title: '用户管理'},
		name: 'user',
		pname:'user',
	},
];

export default router