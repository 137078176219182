const  router = [
	{
		path: "/room",
		component: () => import('../../../pages/room.vue'),
		meta: {title: '库房管理'},
		name: 'room',
		pname:'room',
	},
];

export default router