const  router = [
	{
		path: "/inoutlist",
		component: () => import('../../../pages/inoutlist.vue'),
		meta: {title: '出入库清单'},
		name: 'inoutlist',
		pname:'inoutlist',
	},
];

export default router