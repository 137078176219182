<template>
	<div class="login-box">
		<div>
			<img v-if="logo.account_logo != ''" class="login-logo" :src="logo.account_logo" />
			<img  class="login-logo" src="../assets/image/logo_vip_card@2x.png" />
		</div>
		<div class="flex center alcenter" style="padding-top: 100px;">
			<div class="flex">
				
				<a-card   class="login-card" :bordered="false" style="width:500px;height: 420px;">
					
					<div class="ft26 cl-main ftw500">帐号密码登录</div>
					
					<div class=" flex center mt30">
						<div class="login-mobile-main">
						 
						  
							<a-form-model :model="form" size="large" :label-col="labelCol" :wrapper-col="wrapperCol">
						      <a-form-model-item label="帐号">
						        <a-input size="large"  v-model="form.username" placeholder="输入帐号"/>
						      </a-form-model-item>
							  <a-form-model-item label="密码">
							     <a-input size="large" type="password"  v-model="form.password" placeholder="输入密码"/>
							  </a-form-model-item>
							</a-form-model>	 
							 <div class="mt20 flex alcenter">
							 	<a-checkbox  :checked="agree" @change="checkChange"></a-checkbox>
							 	<div class="ft12 ml10">
							 		记住账号密码
							 	</div>
							 </div>
							<a-button :loading="loginLoading" type="primary" size="large" style="width: 100%; margin-top: 20px;" @click="onLogin()">登录</a-button>
							
						</div>
						
					</div>
					
				</a-card>		
				

				
				<!-- <a-card  class="login-card" :bordered="false" style="width:360px;height: 420px; margin-left: 16px;">
						<div class="ft26 text-center ftw500 cl-main">他们都在用Vipcard云</div>
						<div class="mt12 ft16 text-center">落地会员营销、门店新零售、门店新服务</div>
						<div class="anli-list">
							<img src="../assets/image/anli.png" />
						</div>
				</a-card> -->	
			</div>
		</div>
		<div style="margin-top: 100px;" class="text-center ft12 cl-notice">© 2012 - 2020 {{logo.site_url}}</div>
	</div>
</template>

<script>

	export  default{
	
		data(){
			return {
				 labelCol: { span: 4},
				  wrapperCol: { span: 18 },
				form:{
					username:'',
					password:'',
				},
				logo:{
					account_logo:'',
					site_url:"vipcard.cloud",
				},
				agree:false,
				loginLoading:false,
				loading:false,
				
			}
		},
		created() {
			this.form.username=localStorage.getItem('username');
			this.form.password=localStorage.getItem('password');
			if(this.form.username && this.form.password){
				this.agree=true;
			}
		},
		mounted() {
			//绑定事件
			window.addEventListener('keydown', this.keyDown);
		},
		methods:{
			
			onLogin(){
				if(this.loginLoading==true) return;
				this.loginLoading=true;
				this.$http.api('admin/adminLogin',{
					username:this.form.username,
					password:this.form.password
				}).then(res=>{
					localStorage.setItem('storeroom-token',res.auth_token);
					if(this.agree==true){
						localStorage.setItem('username',this.form.username);
						localStorage.setItem('password',this.form.password);
					}else{
						localStorage.removeItem('username');
						localStorage.removeItem('password');
					}
					this.loginLoading=false;
					localStorage.setItem('adminInfo',JSON.stringify(res.adminInfo));
					this.setAdminInfo(res.adminInfo);
					this.$message.success('登录成功', 1, () => {
						this.$router.push('/account');
					});
				}).catch(res=>{
					this.loginLoading=false;
				})
			},
			checkChange(){
				this.agree = !this.agree;
			},
			keyDown(e) {
				//如果是回车则执行登录方法
				if (e.keyCode == 13) {
					this.onLogin();
				}
			},
		},
		destroyed() {
			window.removeEventListener('keydown', this.keyDown, false);
		}
	}
</script>

<style>


	.login-box{
		padding: 30px 40px;
	}
	.login-logo{
		width: 160px;
	}
	.login-card{
		border-radius: 8px;
		overflow: hidden;
		position: relative;
	}
	.login-mobile-main{
		padding-top: 30px;
		width: 360px;
	}
	.anli-list{
		    margin: 68px 11px 0;
		    height: 224px;
		    background-image: url(../assets/image/anli-bg.png);
		    background-size: 100% auto;
		    background-repeat: no-repeat;
		    background-position: 50%;
	}
	.anli-list img{
		width: 100%;
	}
	.login-type{
		position: absolute;
		right: 0px;
		top: 0px;
	}
	.login-tag{
		position: relative;
		padding: 6px 8px;
		border-radius: 2px;
		font-size: 14px;
		color: #fff;
		background: #5533ff;
	}
	.login-tag::after{
		    content: "";
		    position: absolute;
		    top: 50%;
		    right: -4px;
		    transform: translateY(-50%);
		    width: 0;
		    height: 0;
		    border-top: 4px solid transparent;
		    border-bottom: 4px solid transparent;
		    border-left: 4px solid #5533ff;
	}
	.login-type img{
		width: 80px;
		height: 80px;
	}
</style>