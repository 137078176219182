<template>
	<div>
		<a-modal title="查看清单" :width="750" :visible="visible"   @cancel="handleCancel" :footer="null">
			<div>
				<a-spin :spinning="loading">
					<div class="mb20 flex alcenter " v-if="jurisdiction!=0">
						<a-button type="primary" class="mr10" @click="inOutAct(1)" v-if="jurisdiction==1 || jurisdiction==3">出库</a-button>
						<a-button type="primary" class="mr10"  @click="inOutAct(2)" v-if="jurisdiction==2 || jurisdiction==3">入库</a-button>
					</div>
					
					<div class="mt20 flex wrap center" >
						<table class="list wxb-table" style="width: 100%;">
							<thead>
								<tr>
									<th>规格型号</th>
									<th>备件编号</th>
									<th>仓库位置</th>
									<th>领用单位</th>
									<th>领用人</th>
									<th>备注</th>
									<th>出入库</th>
									<th>数量</th>
									<th>操作</th>
								</tr>
							</thead>
							<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
								<tr v-for="(item,index) in datas">
									<td>
										{{item.specifications}}
									</td>
									
									<td>{{item.spare_number}}</td>
									<td>{{item.warehouse_location}}</td>
									<td>{{item.receiving_name ? item.receiving_name : '-'}}</td>
									<td>{{item.user_man_name?item.user_man_name:'-'}}</td>
									<td>{{item.remark ? item.remark : '-'}}</td>
									<td>
										{{item.type_mean}}
									</td>
									<td>
										{{item.num}}
									</td>
									<td>
										<div class="flex center alcenter omen-action" >
											<a-dropdown>
												<div class="flex alcenter" >
													<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
												</div>
												<a-menu slot="overlay">
													<a-menu-item >
														<a href="javascript:;" @click="delInoutlistAct(index)">
															<i class="iconfont icondelete"></i>
															<span class="ml10">删除记录</span>
														</a>
													</a-menu-item>
												</a-menu>
											</a-dropdown>
										</div>
									</td>
								</tr>
							</tbody>
							
							<tbody v-else>
								<tr>
									<td colspan="8">
										<a-empty/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="flex center mt10">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					
					<div v-if="inOutVisible">
						<in-out :visible="inOutVisible" :material_id="material_id" :type="type" @cancel="cancelInOut" @ok="okInOut"></in-out>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import InOut from './InOutList/modal/InOut.vue';
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			material_id:{
				type:Number,
				default:0
			}
		},
		
		components:{
			InOut,
		},
		
		data(){
			return{
				loading:false,
				confirmLoading:false,
				inOutVisible:false,
				type:0,
				total:0,
				pageSize:5,
				page:1,
				jurisdiction:0,
				datas:[],
				search:{
					name:'',
				}
				
			}
		},
		created() {
			this.getLists();
		},
		methods:{
			/**
			 * 加载本页数据
			 */
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api("admin/getMaterialInOutList",{
					material_id:this.material_id,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.datas=res.list;
					this.total=res.total;
					this.jurisdiction=res.jurisdiction;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
					this.$emit('cancel');
				})
			},
			
			
			//出入库操作
			inOutAct(type){
				this.type=type;
				this.inOutVisible=true;
			},
			cancelInOut(){
				this.inOutVisible=false;
			},
			okInOut(){
				this.inOutVisible=false;
				this.getLists();
			},
			
			delInoutlistAct(index){
				this.$confirm({
					title: '确定删除这个记录吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delInoutlist', {
								list_id: this.datas[index].list_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			
			/**
			 * 给父组件发出关闭弹窗的指令
			 */
			handleCancel(){
				this.$emit('cancel');
			},
			
			/**
			 * 分页的跳转
			 * @param {Object} e
			 */
			pageChange(e){
			    this.page = parseInt(e);
			    this.getLists();
			},
			
			/**
			 * 查询的操作
			 */
			searchAct(){
			    this.page = 1;
			    this.getLists();
			},
			
			/**
			 * 取消查询的操作
			 */
			cancelAct(){
			    this.page = 1;
			    this.searchCancel();
			    this.getLists();
			},
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					name:'',
				}
			},
		}
	}
</script>

<style>
	.admin-room-logo{
		width: 48px;
		height: 48px;
	}
</style>
