<template>
	<div>
		<div class="bg-w pd20" style="min-height: 950px;">
			<a-spin :spinning="loading">
				<div class="flex alcenter">
					<a-button type="primary" class="mr10" @click="addMaterialAct()">新增物资</a-button>
					<a-button type="primary" class="mr10" @click="exportExcel()" >导出物资</a-button>
					<a-upload class="mr10" name="file" :showUploadList="false" :customRequest="uploadExcel" :beforeUpload="beforeUpload">
						<a-button type="primary" >导入物资</a-button>
					</a-upload>
					
					<div class="mr10">
						<a-button type="primary" @click="exportTemplateExcel()">模板下载</a-button>
					</div>
				</div>
				<div class="form-seach-box mt20">
					<a-form layout="inline">
						<a-form-item label="物资名称">
							<a-input v-model="search.name" placeholder="请输入物资名称"></a-input>
						</a-form-item>
						
						<a-form-item label="库房">
							<a-input v-model="search.room_name" placeholder="请输入库房"></a-input>
						</a-form-item>
						
						<a-form-item label="编码">
							<a-input v-model="search.code" placeholder="请输入编码"></a-input>
						</a-form-item>
						
						<a-form-item label="备件编号">
							<a-input v-model="search.spare_number" placeholder="请输入备件编号"></a-input>
						</a-form-item>
						
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
				
				<div class="mt20">
					<table class="list wxb-table" style="width: 100%;">
						<thead>
							<tr>
								<th>物资名称</th>
								<th>所属库房</th>
								<th>物资二维码</th>
								<th>物资编码</th>
								<th>规格型号</th>
								<th>备件编号</th>
								<th>基本单位</th>
								<th>仓库位置</th>
								<th>单价</th>
								<th>库存</th>
								<th>添加时间</th>
								<th>操作</th>
							</tr>
						</thead>
						<tbody class="ft14 ftw400 cl-black" v-if="datas.length>0">
							<tr v-for="(item,index) in datas">
								<td>
									{{item.name}}
								</td>
								<td>
									{{item.room.name}}
								</td>
								<td>
									<div class="flex center alcenter" v-if="item.qrcode">
										<show-pic v-model="item.qrcode"></show-pic>
									</div>
									<div class="flex center alcenter" v-else>
										<a-button type="primary" @click="createQrcodeAct(index)">生成二维码</a-button>
									</div>
								</td>
								<td>{{item.code}}</td>
								<td>{{item.specifications}}</td>
								<td>
									{{item.spare_number}}
								</td>
								<td>
									{{item.basic_unit}}
								</td>
								<td>
									{{item.warehouse_location}}
								</td>
								<td>
									{{item.unit_price}}元
								</td>
								<td>
									<div class="flex alcenter center cl-red" v-if="item.quota_warning_status==1">
										{{item.quota}}
									</div>
									<div class="flex alcenter center " v-else>
										{{item.quota}}
									</div>
								</td>
								<td><div class="flex center cl-deepblue">{{item.add_time_format}}</div></td>
								<td>
									<div class="flex center alcenter omen-action" >
										<a-dropdown>
											<div class="flex alcenter" >
												<i class="iconfont iconmore_blue ft24 omen-action-icon"/>
											</div>
											<a-menu slot="overlay">
												<a-menu-item v-if="item.room_id == adminInfo.room_id || adminInfo.role_id == 1">
													<a href="javascript:;" @click="editMaterialAct(index)">
														<i class="iconfont iconedit"></i>
														<span class="ml10">编辑物资</span>
													</a>
												</a-menu-item>
												
												<a-menu-item >
													<a href="javascript:;" @click="seeListAct(index)">
														<i class="iconfont iconshop"></i>
														<span class="ml10">出库入库</span>
													</a>
												</a-menu-item>
												
												<a-menu-item v-if="item.room_id == adminInfo.room_id">
													<a href="javascript:;" @click="delMaterialAct(index)">
														<i class="iconfont icondelete"></i>
														<span class="ml10">删除物资</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</td>
							</tr>
						</tbody>
						
						<tbody v-else>
							<tr>
								<td colspan="12">
									<a-empty/>
								</td>
							</tr>
						</tbody>
					</table>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="total" v-model="page" :pageSize="pageSize" show-less-items />
					</div>
					<div v-if="editMaterialVisible">
						<edit-material :visible="editMaterialVisible" :material_id="material_id" @cancel="cancelEditMaterial"
						@ok="okEditMaterial"></edit-material>
					</div>
					<div v-if="inOutListVisible">
						<in-out-list :visible="inOutListVisible" :material_id="material_id" @cancel="cancelSeeList"></in-out-list>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../common/mixin/list.js';
	import editMaterial from './components/material/modal/editMaterial.vue';
	import InOutList from './components/material/modal/InOutList.vue';
	import showPic from '../components/photo/showPic.vue';
	export default{
		mixins:[listMixin],
		components:{
			editMaterial,
			InOutList,
			showPic
		},
		data(){
			return{
				loading:false,
				editMaterialVisible:false,
				inOutListVisible:false,
				material_id:0,
				datas:[],
				search:{
					name:'',
					room_name:'',
					code:'',
					spare_number:''
				},
				TemplateData: [{
					room_id:'所属库房id（请统一库房导入）',
					name: '物资名称',
					code: '物资编码',
					specifications: '规格型号',
					spare_number: '备件编号',
					basic_unit: '基本单位',
					warehouse_location: '仓库位置',
					unit_price: '单价',
					quota: '库存',
					quota_warning: '库存预警',
					remarks: '备注',
				}],
				json_fields: {
					"room_id":"room_id",
					"name": "name", //常规字段
					"code": "code", //支持嵌套属性
					"specifications": "specifications", //支持嵌套属性
					"spare_number": "spare_number", //支持嵌套属性
					"basic_unit": "basic_unit", //支持嵌套属性
					"warehouse_location": "warehouse_location", //支持嵌套属性
					"unit_price": "unit_price", //支持嵌套属性
					"quota": "quota", //支持嵌套属性
					"quota_warning": "quota_warning", //支持嵌套属性
					"remarks": "remarks", //支持嵌套属性
				},
				excelData: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMaterialList',{
					name:this.search.name,
					room_name:this.search.room_name,
					code:this.search.code,
					spare_number:this.search.spare_number,
					limit:this.pageSize,
					page:this.page,
				}).then(res=>{
					this.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			//导出物资
			exportExcel(){
				
				let href=process.env.VUE_APP_BASE_API + "/admin/exportMaterialexcel?auth_token="+encodeURIComponent(localStorage.getItem('storeroom-token'));
				if(this.search.name != ''){
					 href=href+'&name='+encodeURIComponent(this.search.name);
				}
				if(this.search.room_name != ''){
					href=href+'&room_name='+encodeURIComponent(this.search.room_name);
				}
				if(this.search.code != ''){
					href=href+'&code='+encodeURIComponent(this.search.code) ;
				}
				if(this.search.spare_number != ''){
					href=href+'&spare_number='+encodeURIComponent(this.search.spare_number);
				}
				console.log(href);
				window.location.href=href;
			},
			
			exportTemplateExcel(){
				let href=process.env.VUE_APP_BASE_API + "/admin/exportMaterialTemplateexcel?auth_token="+encodeURIComponent(localStorage.getItem('storeroom-token'));
				window.location.href=href;
			},
			
			
			//编辑物资
			addMaterialAct(index){
				this.material_id=0;
				this.editMaterialVisible=true;
			},
			
			editMaterialAct(index){
				this.material_id=this.datas[index].material_id;
				this.editMaterialVisible=true;
			},
			cancelEditMaterial(){
				this.editMaterialVisible=false;
			},
			okEditMaterial(){
				this.editMaterialVisible=false;
				this.getLists();
			},
			
			//查看清单
			seeListAct(index){
				this.material_id=this.datas[index].material_id;
				this.inOutListVisible=true;
			},
			cancelSeeList(index){
				this.inOutListVisible=false;
			},
			
			searchCancel(){
			    //改变搜索的表单的初始值
				this.search={
					name:'',
					room_name:'',
					code:'',
					spare_number:''
				}
			},
			
			uploadExcel(e) {
				// console.log(e);
				this.fileTemp = e.file;
				if (this.fileTemp) {
					if ((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
						(this.fileTemp.type == 'application/vnd.ms-excel')) {
						this.importfxx(this.fileTemp);
					} else {
						this.$message.error('附件格式错误，请删除后重新上传！');
					}
				} else {
					this.$message.error('请上传附件！');
				}
			
			},
			beforeUpload(file) {
				// console.log(file);
			},
			importfxx(obj) {
				let _this = this;
				let inputDOM = this.$refs.inputer;
				// 通过DOM取文件数据
				this.file = obj;
			
				var rABS = false; //是否将文件读取为二进制字符串
				var f = this.file;
			
				var reader = new FileReader();
				//if (!FileReader.prototype.readAsBinaryString) {
				FileReader.prototype.readAsBinaryString = function(f) {
					var binary = "";
					var rABS = false; //是否将文件读取为二进制字符串
					var pt = this;
					var wb; //读取完成的数据
					var outdata;
					var reader = new FileReader();
					reader.onload = (e) => {
						var bytes = new Uint8Array(reader.result);
						var length = bytes.byteLength;
						for (var i = 0; i < length; i++) {
							binary += String.fromCharCode(bytes[i]);
						}
						//此处引入，用于解析excel
						var XLSX = require("xlsx");
						if (rABS) {
							wb = XLSX.read(btoa(fixdata(binary)), {
								//手动转化
								type: "base64"
							});
						} else {
							wb = XLSX.read(binary, {
								type: "binary"
							});
						}
						outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
						//outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
						//此处可对数据进行处理
						// console.log(outdata);
						let arr = [];
						outdata.map(v => {
							let obj = new Object;
							obj.room_id = v['room_id']
							obj.name = v['name']
							obj.code = v['code']
							obj.specifications = v['specifications']
							obj.spare_number = v['spare_number']
							obj.basic_unit = v['basic_unit']
							obj.warehouse_location = v['warehouse_location']
							obj.unit_price = v['unit_price']
							obj.quota = v['quota']
							obj.quota_warning = v['quota_warning']
							obj.remarks = v['remarks']
							arr.push(obj)
						});
						console.log(arr);
						_this.excelData = JSON.parse(JSON.stringify(arr));
						_this.addExcelMaterial();
					};
			
					reader.readAsArrayBuffer(f);
				};
				if (rABS) {
					reader.readAsArrayBuffer(f);
				} else {
					reader.readAsBinaryString(f);
				}
			
				//this.excelData=_this.excelData;
			
			},
			
			addExcelMaterial(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/addExcelMaterial',{
					excel_data:this.excelData,
				}).then(res=>{
					console.log(res);
					this.$message.success('操作成功',1,()=>{
						this.loading=false;
						this.getLists();
					})
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			createQrcodeAct(index){
				this.$confirm({
					title: '确定生成二维码吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/createMaterialQrcode', {
								material_id: this.datas[index].material_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			},
			
			delMaterialAct(index){
				this.$confirm({
					title: '确定删除这个物资吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delMaterial', {
								material_id: this.datas[index].material_id,
							}).then(res => {
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
								
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			}
		}
	}
</script>

<style>
</style>
