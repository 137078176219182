<template>
	<div>
		<a-spin :spinning="loading">
			<a-layout style="min-height: 100vh;">
				<a-layout-sider style="min-height: 100vh;" width="180px" class="shop-sider">
					<div style="position: fixed;width: 180px;">
						<div class="shop-sider-box">
						<div class="shop-sider-logo">
							<img v-if="logo.store_index_logo != ''" :src="logo.store_index_logo" />
							<img v-else src="../assets/image/logo_vipcard_white@2x.png" />
						</div>
						<div class="shop-sider-menu">
							<div>
							<div v-for="(item,index) in getMenus" :key="index" class="shop-menu-item">
						
								<div v-if="item.child.length == 0" @click="toPath(item)" :class="{active:item.key == getSelectKey}" class="menu-first-item">
									<span class="iconfont" style="margin-right: 12px;" :class="item.icon"></span>
									<span class="ft14 ftw500">{{item.name}}</span>
						
								</div>
								<a-popover v-else placement="right">
									<template slot="content">
										<div class="menu-child-popover" v-for="(child,childIndex) in item.child" :key="childIndex">
											<span @click="toPath(child)" :class="{ftw600:getSelectKey.indexOf(child.key) > -1}">{{child.name}}</span>
											<!-- <span @click="toPath(child)" :class="{ftw600:getSelectKey==child.key}">{{child.name}}</span> -->
											<!-- <span @click="toPath(child)" :class="{ftw600:getSelectPkey==child.key}">{{child.name}}</span> -->
											<span @click="showChildAct(child.key)" class="iconfont ft14" :class="child.show == 1 ? 'icondingzibiaoji-' : 'icondingzi'"
											 style="float: right; margin-right: 0px;"></span>
										</div>
									</template>
									<div class="menu-first-item">
										<span class="iconfont" style="margin-right: 12px;" :class="item.icon"></span>
										<span class="ft14 ftw500">{{item.name}}</span>
										<span class="iconfont iconsanjiao-r" style="float: right; margin-right: 0px;"></span>
									</div>
								</a-popover>
						
								<div v-if="item.showChild > 0" class="menu-child-box flex wrap">
						
									<div v-for="(child,childindex) in  item.child" :key="childindex" @click="toPath(child)" v-show="child.show == 1"
									 class="menu-child-item">
										<span class="text" :class="{active:getSelectKey.indexOf(child.key) > -1}">
											{{child.name}}
										</span>
										<!-- <span class="text" :class="{active:getSelectKey==child.key}">
											{{child.name}}
										</span> -->
										<!-- <span class="text" :class="{active:getSelectPkey==child.key}">
											{{child.name}}
										</span> -->
									</div>
						
								</div>
							</div>
						
							</div>
						
						</div>
						</div>
					</div>
					
				</a-layout-sider>
				<a-layout>
					<a-layout-header class="shop-header" id="shop-header" style="position: fixed; width: calc(100vw - 180px); z-index: 999;">
						<div>
							<div class="flex space alcenter" v-if="getSelectKey == 'index' || getSelectKey == ''">
									<div class="flex alcenter" >
										<span class="ft20 ftw600 cl-main">数字库房</span>
									</div>
									<div class="flex alcenter">
										
							
										<a-dropdown>
											<div class="flex alcenter" style="height: 60px; margin-left: 30px;">
												<img class="shop-logo" src="../assets/image/shoplogo_init.png" />
												<a-icon type="caret-down" />
											</div>
											<a-menu slot="overlay">
							
												<a-menu-item>
													<router-link @click="clearCache()">
														<div style="height: 30px;line-height: 30px;">
															<a-icon type="sync" />
															<span class="ml10">清楚缓存</span>
														</div>
													</router-link>
												</a-menu-item>
												
												<a-menu-item>
													<div @click="logout" style="height: 30px;line-height: 30px;">
														<i class="iconfont icontuichu"></i>
														<span class="ml10">退出管理</span>
													</div>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</div>
							
							
								<a-page-header :title="getNowTitle" sub-title="" @back="() => $router.go(-1)" v-else>
									<template slot="extra">
										<div class="flex alcenter">
											
							
											<a-dropdown>
												<div class="flex alcenter" style="height: 60px; margin-left: 30px;">
													<img class="shop-logo" src="../assets/image/shoplogo_init.png" />
													<a-icon type="caret-down" />
												</div>
												<a-menu slot="overlay">
							
								
													
													<a-menu-item>
														<div @click="logout" style="height: 30px;line-height: 30px;">
															<i class="iconfont icontuichu"></i>
															<span class="ml10">退出管理</span>
														</div>
													</a-menu-item>
												</a-menu>
											</a-dropdown>
										</div>
									</template>
							
								</a-page-header>
							
							
							
						</div>
						
						</a-layout-header>
					<a-layout-content style="margin-top: 60px;">
						<div v-if="loading == false" class="pd20">
							<router-view></router-view>
						</div>
					</a-layout-content>
					<a-layout-footer>
						<div class="text-center ft12 cl-notice">© 2012 - 2020 {{logo.site_url}}</div>
					</a-layout-footer>
				</a-layout>
			</a-layout>
		</a-spin>
	</div>
</template>

<script>
	import {
		menus,
		showchild
	} from '../common/router/menus.js';
	export default {
		data() {
			return {
				menus: [],
				showchild: [],
				loading: false,
				appdata: null,
				logo:{
					store_index_logo:'',
					site_url:"vipcard.cloud",
				},
			}
		},
		created() {
			this.menus = menus;
			this.showchild = showchild;
			this.getDatas();
		},
		computed: {
			getSelectKey() {
				return this.$route.name;
			},
			getNowTitle(){
				return  this.$route.meta.title;
			},
			getSelectPkey(){
				console.log(this.$route);
				return this.$route.meta.pname;
			},
			getMenus() {
				let arr = new Array;
				for (var a in this.menus) {
					let child = new Array;
					let showChild = 0;
					if (this.menus[a].child.length > 0) {
						for (var b in this.menus[a].child) {
							let show = 0;
							if (this.showchild.indexOf(this.menus[a].child[b].key) > -1) {
								show = 1;
								showChild++;
							}
							child.push({
								name: this.menus[a].child[b].name,
								path: this.menus[a].child[b].path,
								key: this.menus[a].child[b].key, //对应的是 路由的name
								show: show
							});
						}
					}
					arr.push({
						name: this.menus[a].name,
						path: this.menus[a].path,
						icon: this.menus[a].icon,
						key: this.menus[a].key, //对应的是 路由的name
						child: child,
						showChild: showChild
					});
				}
				console.log(arr);
				return arr;
			}
		},
		methods: {
			changeStore() {
				location.href = process.env.VUE_APP_ACCOUNT_URL;
			},
			getDatas() {
				this.loading = true;
				this.$http.api('store/layoutData').then(res => {
					this.loading = false;
					console.log(res);
					this.appdata = res.appdata;
					localStorage.setItem('appdata',JSON.stringify(res.appdata));
					this.feedback_num = res.feedback_num;
					this.is_switch = res.is_switch;
					this.notice_num = res.notice_num;
					this.logo=res.logo;
					if (res.showmenu && res.showmenu.length > 0) {
						this.showchild = res.showmenu;
					}
				}).catch(res => {
					this.loading = false;
				});
			},
			toPath(router) {
				if (this.getSelectKey != router.key) {
					this.$router.push(router.path);
				}

			},
			showChildAct(key) {
				let index = this.showchild.indexOf(key);
				if (index > -1) {
					this.showchild.splice(index, 1);
				} else {
					this.showchild.push(key);
				}
			},
			clearCache(){
				
			},
			logout() {
				localStorage.removeItem('storeroom-token');
				localStorage.removeItem('adminInfo')
				this.$router.push('/');
			},
			
		}
	}
</script>

<style>

	.shop-sider-menu {
		overflow-y: scroll;
		padding-top: 20px;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 60px);
	}
	
	.shop-sider-menu::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	.shop-sider-menu::-webkit-scrollbar-thumb {
			background: hsl(0deg 0% 100% / 11%);
			border-radius: 3px;
			-webkit-box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 11%);
		}
	.shop-menu-item {
		padding-bottom: 15px;
	}

	.menu-child-popover {
		width: 120px;
		height: 34px;
		line-height: 34px;
		cursor: pointer;
	}

	.menu-first-item {
		padding: 0 24px;
		height: 34px;
		line-height: 34px;
		cursor: pointer;
		color: rgba(255, 255, 255, .9);
		transition: background-color 0.4s;
	}

	.menu-child-box {
		padding-left: 24px;
		padding-right: 4px;
	}

	.menu-child-item {
		width: 50%;
		padding-top: 8px;
		padding-bottom: 2px;
	}

	.menu-child-item .text {
		height: 22px;
		line-height: 22px;
		padding: 0 4px;
		text-align: center;
		display: inline-block;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.8);
		cursor: pointer;
		width: auto;
		white-space: nowrap;
		position: relative;
		-webkit-border-radius: 2px;
		border-radius: 2px;
		transition: all 0.4s;
	}

	.menu-child-item .text:hover {
		background: rgba(245, 246, 249, 0.1);
	}

	.menu-child-item .text.active {
		color: #25324e;
		background: #f5f6f9;
	}

	.menu-first-item:hover {
		background: rgba(245, 246, 249, 0.1);
	}

	.menu-first-item.active {
		color: #25324e;
		background: #f5f6f9;
	}

	.shop-header {
		height: 60px;
		background: #FFFFFF;
		padding: 0 30px;
		line-height: 60px;
	}

	.shop-header .ant-page-header {
		padding: 0 0;
	}

	.shop-header .ant-page-header-back {
		line-height: 60px;
		margin: auto 0;
		margin-right: 10px;
	}

	.shop-header .ant-page-header-heading-title {
		line-height: 60px;
	}

	.shop-sider {
		background: #273043;
		box-shadow: 2px 0 3px -1px rgba(0, 0, 0, .06);
		padding: 0;
	}

	.shop-sider-logo {
		width: 180px;
		height: 60px;
		background: #5533FF;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.shop-sider-logo img {
		width: 138px;
	}

	.shop-logo {
		width: 24px;
		height: 24px;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		overflow: hidden;
		margin-right: 3px;
	}
</style>
