import axios from 'axios'
import qs from 'qs'
import {message} from 'ant-design-vue'
import  router from '../router/router.js';
axios.defaults.timeout = 200000;

axios.defaults.baseURL = process.env.VUE_APP_BASE_API + "/";
// axios.defaults.baseURL = 'http://store.0123china.cn'
console.log(axios.defaults.baseURL,"axios.defaults.baseURL")
//POST传参序列化
axios.interceptors.request.use((config) => {
    config.data = config.data || {};
    config.data.auth_token = localStorage.getItem('storeroom-token');
    config.data = qs.stringify(config.data);
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    };
    return config;
}, (error) => {
    message.error('网络请求超时');

    return Promise.reject(error);
});

//返回状态判断
axios.interceptors.response.use((res) => {
    if (res.status == 200) {
        return res;
    } else {
        return Promise.reject(res);
    }
}, (error) => {
    //console.log(error);
    return Promise.reject(error);
});

export function api(url, params) {
    return axios.post(url, params)
        .then(response => {
            switch (response.data.code) {
                case 100:
                    message.error(response.data.msg);
					localStorage.removeItem('storeroom-token');
					router.push('/');
                    return Promise.reject(response.data.msg || 'error');
                
                case 200:
                    return response.data.data;
                case 400:  //400 为提示类型错误
					message.error(response.data.msg);
                    return Promise.reject(response.data.msg || 'error');
                case 500:
					return Promise.reject('error');
                    break;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            return Promise.reject(msg || 'error')
        });

}
